export default {
	color: {
		dark: `#000000`,
		light: `#ffffff`,
		yellow: `#FFD800`,
		blue: `#00A7E1`,
	},
	font: {
		body: `'Roboto', sans-serif`,
		display: `'Abolition', sans-serif;`,
	},
};
