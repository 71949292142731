import React from 'react';
import styled from 'styled-components';

import Facebook from './facebook';
import Instagram from './instagram';
import Untapped from './untapped';

const StyledSocialIcons = styled.ul`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: stretch;

	li {
		display: inline-block;
		height: 3rem;
		padding: 0.5rem;

		svg {
			width: 1.5rem;
			height: 1.5rem;

			path,
			circle,
			polygon {
				transition: 300ms;
			}
		}

		+ li {
			margin-left: 1rem;
		}

		&:hover {
			svg {
				path,
				circle,
				polygon {
					fill: ${(p) => p.theme.color.blue};
				}
			}
		}
	}
`;

const SocialIcons = () => {
	return (
		<StyledSocialIcons>
			<li>
				<a
					href="https://www.facebook.com/Flying-Monkeys-Craft-Brewery-108949405799023/"
					target="_blank"
					rel="noopener noreferrer"
					title="Flying Monkeys on Facebook"
				>
					<Facebook />
				</a>
			</li>
			<li>
				<a
					href="https://www.instagram.com/theflyingmonkeys/"
					target="_blank"
					rel="noopener noreferrer"
					title="Flying Monkeys on Instagram"
				>
					<Instagram />
				</a>
			</li>
			<li>
				<a
					href="https://untappd.com/FlyingMonkeysCraftBrewery"
					target="_blank"
					rel="noopener noreferrer"
					title="Flying Monkeys on Untapped"
				>
					<Untapped />
				</a>
			</li>
		</StyledSocialIcons>
	);
};

export default SocialIcons;
