import { createGlobalStyle } from 'styled-components';
import borderSource from '../images/border.svg';

export default createGlobalStyle`
	/* Import fonts here */
	@import url('https://use.typekit.net/izq2ydw.css');
	@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');
	/* font-family: 'Roboto', sans-serif; */
	/* font-family: 'Abolition', sans-serif; */

	html, body {
		font-size: 16px;
	}

	body {
		color: ${(p) => p.theme.color.dark};
		background-color: ${(p) => p.theme.color.light};
		font-family: ${(p) => p.theme.font.body};
		* {
			box-sizing: border-box;
		}
	}
	
	h1, h2, h3, h4, h5, h6 {
		font-family: ${(p) => p.theme.font.display};
	}

	.has-border {
		border-image-source: url(${borderSource});
		border-image-slice: 10;
		border-image-repeat: repeat;
		border-width: 32px;
		border-style: solid;
	}

	.visually-hidden {
		position: absolute !important;
		clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
		clip: rect(1px, 1px, 1px, 1px);
		padding:0 !important;
		border:0 !important;
		height: 1px !important; 
		width: 1px !important; 
		overflow: hidden;
	}
`;
