import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import { ThemeProvider } from 'styled-components';
import Bulma from '../../styles/bulma';
import GlobalStyle from '../../styles/global';
import theme from '../../styles/theme';

import Header from './header';
import Footer from './footer';

export default ({ children, home, isSeltzer }) => (
	<ParallaxProvider>
		<ThemeProvider theme={theme}>
			<>
				<Bulma />
				<GlobalStyle />
				<Header home={home} isSeltzer={isSeltzer}/>
				<main>{children}</main>
				<Footer />
			</>
		</ThemeProvider>
	</ParallaxProvider>
);
