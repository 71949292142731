import styled from 'styled-components';
import menuBar from '../images/menu-bar.svg';
import menuWave from '../images/Menu_wave.svg';

export default styled.header`
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0;
	position: absolute;
	width: 100%;
	.logo-wrap {
		flex-basis: 30rem;
		min-height: 244px;
		display: flex;
		align-items: center;
		img {
			height: auto;
			opacity: ${(p) => {
				return p.home ? 0 : 1;
			}};
		}
		@media (max-width: 999px) {
			min-height: 0;
			img {
				max-width: 250px;
			}
		}
	}
	.wave {
		background-image: ${(p) => (p.home ? `` : `url(${menuWave})`)};
		width: 100%;
		position: absolute;
		top: 82px;
		height: 54px;
		background-size: cover;
		z-index: -1;
		@media (max-width: 999px) {
			top: 33px;
		}
	}
	nav {
		background-image: ${(p) => (p.home ? `url(${menuBar})` : '')};
		@media (max-width: 999px) {
			background-image: none;
		}
		background-size: auto 100%;
		padding: 0 4rem 0 1rem;
		margin-top: -2rem;

		> ul {
			display: flex;
			transition: 300ms ease-in-out;

			> li {
				display: inline-block;
				padding: 0.5rem 1.25rem;

				a {
					line-height: 2.5rem;
					font-size: 1.25rem;
					color: ${(p) => p.theme.color.yellow};
					font-family: ${(p) => p.theme.font.display};
					text-decoration: none;
					transition: color 300ms;

					&:hover {
						color: ${(p) => p.theme.color.blue};
					}
				}
				img {
					width: 1.5rem;
					max-width: 1.5rem;
					height: 1.5rem;
					vertical-align: middle;
				}

				&.social-icons {
					padding: 0;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
				}
			}

			@media (max-width: 999px) {
				flex-direction: column;
				position: fixed;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				align-items: center;
				justify-content: center;
				background: rgba(0, 0, 0, 0.95);
				opacity: 0;
				transform: translateX(100%);

				&.is-active {
					opacity: 1;
					transform: translateX(0);
				}

				> li {
					text-align: center;
				}
			}
		}

		&.is-active {
			background-image: none;
		}

		@media (max-width: 999px) {
			margin-top: 0;
			padding: 0.7rem 1rem 0 1rem;
		}
	}

	.mobile-button {
		text-align: center;
		margin-top: -20px;
		border-radius: 4px;

		background-color: ${(p) => (p.home ? `rgba(0, 0, 0, 0.8)` : '')};
		&.is-active {
			position: relative;
			z-index: 10;
		}

		button.hamburger {
			cursor: pointer;
			opacity: 1 !important;
			height: 56px;
			outline: none;
			.hamburger-box {
				.hamburger-inner {
					background-color: ${(p) => p.theme.color.yellow};

					&::before,
					&::after {
						background-color: ${(p) => p.theme.color.yellow};
					}
				}
			}

			&.is-active {
				.hamburger-box {
					.hamburger-inner {
						background-color: ${(p) => p.theme.color.yellow};

						&::before,
						&::after {
							background-color: ${(p) => p.theme.color.yellow};
						}
					}
				}
			}
		}

		@media (min-width: 1000px) {
			display: none;
		}
	}
`;
