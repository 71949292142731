import React, { useState } from 'react';
import { Link } from 'gatsby';

import StyledHeader from './styles/header';
import Hamburgers from '../../../styles/hamburgers';

import logo from './images/logo.png';
import seltzerLogo from "./images/InfiniteMinds_Logo_wGlow.png"

import SocialIcons from '../social';

export default ({ home, isSeltzer }) => {
	const [isActive, setIsActive] = useState(false);

	return (
		<StyledHeader home={home}>
			<Hamburgers />
			<div className="wave" />
			<div className="logo-wrap">
				<Link to="/">
					{isSeltzer ? 
					<img src={seltzerLogo} alt="Infinite Minds" /> :
					<img src={logo} alt="Flying Monkeys Craft Brewery" />}
				</Link>
			</div>
			<nav className={!!isActive ? `is-active` : null}>
				<div className={`mobile-button${isActive ? ' is-active' : ''}`}>
					<button
						className={`hamburger hamburger--spring${
							isActive ? ' is-active' : ''
						}`}
						type="button"
						onClick={() => setIsActive(!isActive)}
					>
						<span className="hamburger-box">
							<span className="hamburger-inner"></span>
						</span>
					</button>
				</div>
				<ul className={!!isActive ? `is-active` : null}>
					<li>
						<Link to="/brewery">BREWERY</Link>
					</li>
					<li>
						<Link to="/beers">BEERS</Link>
					</li>
					<li>
						<Link to="/seltzers">SELTZERS</Link>
					</li>
					<li>
						<Link to="/taproom">TAPROOM</Link>
					</li>
					<li>
						<Link to="/tours">TOURS</Link>
					</li>
					<li>
						<a href="https://shop.flyingmonkeys.ca" target="__blank">
							SHOP
						</a>
					</li>
					<li>
						<Link to="/contact">CONTACT</Link>
					</li>
					<li className="social-icons">
						<SocialIcons />
					</li>
				</ul>
			</nav>
		</StyledHeader>
	);
};
