import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SocialIcons from './social';

import blackEdge from '../../images/black-edge.png';
import logo from '../../images/yellow-logo.png';
import yellowEdge from '../../images/yellow-edge.png';

const FooterMain = styled.div`
	background-color: black;
	position: relative;
	color: white;

	nav {
		margin: 0 auto;
		width: 100%;
		padding: 0 150px;
		a {
			line-height: 2.5rem;
			font-size: 1.25rem;
			color: ${(p) => p.theme.color.yellow};
			font-family: ${(p) => p.theme.font.display};
			text-decoration: none;
			transition: color 300ms;

			&:hover {
				color: ${(p) => p.theme.color.blue};
			}
		}
		@media (max-width: 999px) {
			display: none;
		}
	}
	.constrain {
		display: flex;
		max-width: 1200px;
		margin: 0 auto;
		padding: 1rem 1rem 2rem 1rem;
		align-items: center;
		justify-content: space-between;
		height: 128px;
		@media (max-width: 999px) {
			flex-direction: column;
			height: 148px;
			.left,
			.right {
				flex: 0 0 0;
			}
		}
	}
	.logo {
		width: 200px;

		@media (max-width: 399px) {
			margin-bottom: 1rem;
		}
	}
	.left {
		flex: 0 0 200px;
	}
	.right {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 0 0 200px;
		@media (min-width: 400px) {
			justify-content: flex-end;
		}
	}

	.black-edge {
		background-image: url(${blackEdge});
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		width: 100%;
		transform: translateY(calc(-100% + 1px));
		padding-top: 1%;
	}
`;

const Legal = styled.div`
	color: black;
	background-color: ${(p) => p.theme.color.yellow};
	text-align: center;
	padding: 0.5rem 0;
	font-size: 0.875rem;
	position: relative;

	.yellow-edge {
		background-image: url(${yellowEdge});
		background-size: cover;
		background-position: center top;
		background-repeat: no-repeat;
		position: absolute;
		right: 0;
		left: 0;
		top: 0;
		width: 100%;
		transform: translateY(calc(-100% + 1px));
		padding-top: 1%;
	}
`;

export default () => (
	<footer>
		<FooterMain>
			<div className="black-edge" />
			<div className="constrain">
				<div className="left">
					<Link to="/">
						<img src={logo} alt="Flying Monkeys Logo" className="logo" />
					</Link>
				</div>
				<nav>
					<div className="columns">
						<div className="column">
							<div>
								<Link to="/brewery">BREWERY</Link>
							</div>
							<div>
								<Link to="/taproom">TAPROOM</Link>
							</div>
						</div>
						<div className="column has-text-centered">
							<div className="">
								<Link to="/beers">BEERS</Link>
							</div>
							<div>
								<Link to="/tours">TOURS</Link>	
							</div>
						</div>
						<div className="column has-text-right">
							<div>
								<Link to="/seltzers">SELTZERS</Link>
							</div>
							<div>
								<Link to="/contact">CONTACT</Link>
							</div>
						</div>
					</div>
				</nav>
				<div className="right">
					<SocialIcons />
				</div>
			</div>
		</FooterMain>
		<Legal>
			<div className="yellow-edge" />
			<p>© 2020, FLYING MONKEYS CRAFT BREWERY</p>
		</Legal>
	</footer>
);
